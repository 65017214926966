.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}
  
.trigger:hover {
    color: #1890ff;
}
  
.logo {
    height: 32px;
    margin: 10px;
    margin-top: 20px;
    text-align: center;
}
  
.site-layout .site-layout-background {
    background: #fff;
}