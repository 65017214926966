@import "antd/dist/reset.css";

html,
body,
div#root {
  height: 100%;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.ant-drawer-content-wrapper {
  max-width: 100%;
}
a {
  color: #0392a8 !important;
}
a:hover {
  text-decoration: underline !important;
}
